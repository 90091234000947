<template>
  <v-container v-if="renderNow" style="background-color: #f8f8fa">
    <v-row>
      <v-col :cols="cols.left">
        <teacher-account-details-form
          :initialProfileData="profile"
        ></teacher-account-details-form>
      </v-col>
      <v-col :cols="cols.right">
        <student-security-options
          :passUpDate="profile.password_updated_at"
        ></student-security-options>
      </v-col>
    </v-row>
  </v-container>
  <v-container
    v-else
    style="height: 40vh"
    class="d-flex justify-center align-center"
  >
    <v-progress-circular color="primary" indeterminate></v-progress-circular>
  </v-container>
</template>

<script>
import TeacherAccountDetailsForm from "./TeacherAccountDetailsForm.vue";
import StudentSecurityOptions from "../studentaccount/StudentSecurityOptions.vue";

export default {
  name: "TeacherAccountDetails",
  components: { TeacherAccountDetailsForm, StudentSecurityOptions },
  async created() {
    await this.getProfileInfo();
  },
  data() {
    return {
      renderNow: false,
      profile: undefined
    };
  },
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    cols() {
      return {
        left: this.breakPointValues(["12", "12", "8", "6"]),
        right: this.breakPointValues(["12", "12", "4", "6"])
      };
    }
  },
  methods: {
    breakPointValues(values) {
      let s = this.screenWidth;
      return s < 600
        ? values[0]
        : s < 961
        ? values[1]
        : s < 1903
        ? values[2]
        : values[3];
    },
    async getProfileInfo() {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ){
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      try {
        this.profile = await this.$store.dispatch("userAF/getTeacherProfile");
        this.renderNow = true;
      } catch (e) {
        this.$root.$emit("alert", ["Alert", e.message])
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
