var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderNow
    ? _c(
        "v-container",
        { attrs: { id: "main" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                [
                  _c("student-account-details-form", {
                    attrs: { initialProfileData: _vm.profileData },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-6", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                [
                  _c("student-security-options", {
                    attrs: { passUpDate: _vm.profileData.password_updated_at },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-6", attrs: { "no-gutters": "" } },
            [_c("v-col", [_c("delete-account-component")], 1)],
            1
          ),
        ],
        1
      )
    : _c(
        "v-container",
        {
          staticClass: "d-flex justify-center align-center",
          staticStyle: { height: "40vh" },
        },
        [
          _c("v-progress-circular", {
            attrs: { color: "primary", indeterminate: "" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }