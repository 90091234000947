var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", [
    _c(
      "p",
      { staticClass: "mb-12 ml-9 mt-2 dark--text font-weight-bold text-h5" },
      [_vm._v(" My Account ")]
    ),
    _c("div", [
      !_vm.isTeacher
        ? _c(
            "div",
            { staticClass: "mr-3 ml-3 mb-0" },
            [
              _vm.screenWidth >= 700
                ? _c(
                    "v-tabs",
                    {
                      staticClass: "tab-bar",
                      attrs: {
                        "hide-slider": "",
                        "background-color": "transparent",
                        "show-arrows": true,
                      },
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    _vm._l(_vm.tabItems, function (item, i) {
                      return _c(
                        "v-tab",
                        {
                          key: i,
                          staticClass:
                            "single-tab-item text-h6 py-3 px-8 xl:text-xl lg:text-xl",
                          style: {
                            cursor: _vm.isTabDisabled(item)
                              ? "not-allowed"
                              : "pointer",
                          },
                          attrs: {
                            "active-class": "selected-tab text-h6 py-3 px-8",
                            disabled: _vm.isTabDisabled(item),
                          },
                          on: {
                            click: function ($event) {
                              _vm.currentTab = item.title
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.title) + " ")]
                      )
                    }),
                    1
                  )
                : _c("v-select", {
                    staticClass: "selectOptions",
                    attrs: {
                      items: _vm.selectItems,
                      "background-color": "white",
                      outlined: "",
                      "item-disabled": "disable",
                      "menu-props": { rounded: "sm" },
                    },
                    model: {
                      value: _vm.currentTab,
                      callback: function ($$v) {
                        _vm.currentTab = $$v
                      },
                      expression: "currentTab",
                    },
                  }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {},
        [
          _vm.isTeacher
            ? _c("teacher-account-details")
            : _vm.isStudent && _vm.currentTab === "Account"
            ? _c("student-account-details")
            : _vm.isStudent && _vm.currentTab === "Subscriptions"
            ? _c("Subscriptions")
            : _vm.isStudent && _vm.currentTab === "Transactions"
            ? _c("Transactions")
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }