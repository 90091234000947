<template>
  <v-container class="overflow-hidden pa-0 ma-0">
    <!-- <h2 class="mt-2 mb-5" style="color: #4c4b4b">My Account</h2> -->
    <!-- style="box-shadow: none; padding: 27px 32px 35px 32px" -->
    <div
      class="pa-5 mt-4 bg-white rounded"
      :outlined="!readOnly ? true : false">
      <v-row
        style="gap:8px"
        :class="$vuetify.breakpoint.width < 1100 ? 'flex-column' : ''">
        <v-col>
          <v-card
            color="#E5F4FB"
            class="d-flex justify-center align-center pa-3 pa-sm-5"
            min-height="190"
            flat>
            <div :class="$vuetify.breakpoint.smAndDown ? 'd-flex flex-column justify-center align-center': 'd-flex'" style="gap: 16px !important"
            >
              <v-avatar
              align-self-center
                color="primary"
                :class="
                  classes.avatar[isExist(updatedProfileData, 'avatar_link')]
                "
                :height="sizes.avatar.height"
                :width="sizes.avatar.width">
                <img
                  v-if="avatarDisplayCondition"
                  :src="getImageURL()"
                  alt=""
                  width="148"
                  height="148"
                />
                <span v-else>{{ initials }}</span>
                <v-menu offset-y v-model="showAvatarChageMenu">
                  <template #activator="{ on, attrs }">
                    <button
                      v-show="!readOnly"
                      class="avatar-edit-button pa-1 xl:text-base lg:text-sm md:text-sm sm:text-xs"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Change
                    </button>
                  </template>
                  <v-list style="padding: 0px 0px">
                    <v-list-item
                      @click="changeAvatar"
                      class="px-3 py-0 d-flex justify-center"
                      >Upload Photo
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      @click="removeAvatar"
                      class="px-3 py-0 d-flex justify-center"
                      >Remove
                    </v-list-item>
                  </v-list>
                </v-menu>
                <input
                  ref="file"
                  type="file"
                  accept="image/*"
                  style="display: none"
                  @change="loadImageFile($event.target.files)"
                />
              </v-avatar>
              <div :class="$vuetify.breakpoint.smAndDown ? 'd-flex flex-column justify-center align-center': ''">
                <div
                  style="color: #29637d; 
                  max-height: 65px !important; "
                  :style="`max-width: ${$vuetify.breakpoint.smAndDown ? 200 : 370  }px;`"
                  class="xl:text-3xl lg:text-2xl md:text-xl text-base text-truncate"
                  :class="$vuetify.breakpoint.smAndDown ? 'mt-2': '' "
                >
                {{ profile.name }}

                </div>
                <div
                  class="ft-medium xl:text-base lg:text-base md:text-base sm:text-sm mt-2"
                >
                  Student
                </div>
                <div class="mt-2">
                  <v-btn
                    style="
                        text-transform: none;
                        color: #0099dc;
                        padding: none;
                        border-color: #0099dc;
                      "
                      class="px-8"
                    :class="classes.editButton"
                    outlined
                    small
                    @click="makeFormEditable"
                  >
                    Edit
                  </v-btn>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col>
          <v-col class="pa-0 ma-0">
            <p class="acc-header">Email Address</p>
            <!-- :hide-details="isSmall" -->
            <v-text-field
              readonly
              height="46px"
              outlined
              dense
              :value="profile.email"
              class="acc-font mt-2"
            >
            </v-text-field>
          </v-col>
          <v-col class="pa-0 ma-0">
            <p class="acc-header ">Phone Number</p>
            <v-text-field
              v-model="updatedProfileData.phone"
              :readonly="readOnly"
              :hide-details="isSmall"
              dense
              outlined
              height="46px"
              :placeholder="profile.phone"
              class="acc-font mt-2"
              :rules="[phoneValidation]"
              ref="phoneNumberField"
            >
            </v-text-field>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col>
          <p class="acc-header ">Address</p>
          <v-textarea
            v-model="updatedProfileData.address"
            :readonly="readOnly"
            outlined
            dense
            class="acc-font mt-2"
            :placeholder="
              readOnly
                ? 'N/A'
                : isExist(updatedProfileData, 'address')
                ? updatedProfileData.address
                : 'Type your present address here...'
            "
            :hide-details="isSmall"
            on-resize
            rows="3"
            :counter="aboutTextMaxString"
          >
          </v-textarea>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col>
          <p class="acc-font">Institution</p>
          <v-text-field
            v-model="updatedProfileData.institution"
            class="acc-font"
            :readonly="readOnly"
            outlined
            height="46px"
            :counter="institutionMaxString"
            :hide-details="isSmall"
            :placeholder="
              readOnly
                ? 'N/A'
                : isExist(updatedProfileData, 'institution')
                ? updatedProfileData.institution
                : 'Write you institution here...'
            "
          >
          </v-text-field>
        </v-col>
      </v-row> -->
      <v-row v-if="!readOnly" justify="start" class="pl-3">
        <v-btn
          @click="updateProfileInfo()"
          color="#0099DC"
          class="white--text px-6 py-3"
          style="text-transform: none; box-shadow: none"
          :loading="updatingProfile"
          >Save
        </v-btn>
        <v-btn
          outlined
          class="red--text"
          style="
            text-transform: none;
            border-color: #d6d6d6;
            background-color: #fff;
            margin-left: 10px;
            box-shadow: none;
          "
          @click="cancelSave"
        >
          Cancel
        </v-btn>
      </v-row>
    </div>

    <!-- <submission-alert-message
      ref="banner"
      v-model="showFormAlertMessage"
      :message="alertMessage"
    ></submission-alert-message>
    <br />
    <v-card style="box-shadow: none; padding: 36px 32px; margin-bottom: 20px">
      <v-row justify="space-between">
        <h3 class="sm:text-sm d-flex align-center">Guardian</h3>
      </v-row>

      <v-form v-model="guardianForm">
        <v-row>
          <v-col :cols="screenWidth < 600 ? '12' : '6'">
            <p class="acc-font">Name</p>
            <v-text-field
              :hide-details="isSmall"
              class="acc-font"
              :readonly="readOnly"
              outlined
              height="46px"
              v-model="guardian.name"
            >
            </v-text-field>
          </v-col>
          <v-col :cols="screenWidth < 600 ? '12' : '6'">
            <p class="acc-font">Relationship</p>
            <v-text-field
              :hide-details="isSmall"
              class="acc-font"
              :readonly="readOnly"
              outlined
              height="46px"
              v-model="guardian.relation"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="screenWidth < 600 ? '12' : '6'">
            <p class="acc-font">Phone Number</p>
            <v-text-field
              :hide-details="isSmall"
              class="acc-font"
              :readonly="readOnly"
              outlined
              height="46px"
              :disabled="readOnly ? true : false"
              v-model="guardian.number"
              :rules="readOnly ? [() => true] : [phoneValidation]"
              ref="gphonfield"
            >
            </v-text-field>
          </v-col>
          <v-col :cols="screenWidth < 600 ? '12' : '6'">
            <p class="acc-font">Email</p>
            <v-text-field
              :hide-details="isSmall"
              class="acc-font"
              :readonly="readOnly"
              outlined
              :disabled="readOnly ? true : false"
              height="46px"
              v-model="guardian.email"
              :rules="
                readOnly
                  ? [
                      () => {
                        true;
                      },
                    ]
                  : [emailValidation]
              "
              ref="gemailfield"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card> -->
  </v-container>
</template>

<script>
import _ from "lodash";
import { FieldValidations } from "/global/utils/validations.js";
// import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage.vue";

export default {
  name: "StudentAccountDetailsForm",
  props: {
    initialProfileData: { type: Object, required: false },
  },
  // components: { SubmissionAlertMessage },
  mixins: [FieldValidations],
  data() {
    return {
      readOnly: true,
      profile: undefined,
      updatedProfileData: {
        name: undefined,
        phone: undefined,
        // institution: undefined,
        address: undefined,
        // guardians: [],
        avatar_link: undefined,
      },
      // guardian: {
      //   name: undefined,
      //   relation: undefined,
      //   number: undefined,
      //   email: undefined
      // },
      aboutTextMaxString: 500,
      // institutionMaxString: 200,
      updatedDataSend: {},
      showAvatarChageMenu: false,
      localAvatar: undefined,
      clearAvatar: false,
      // guardianForm: undefined,
      // showFormAlertMessage: false,
      updatingProfile: false,
    };
  },
  created() {
    const isOnline = window.navigator.onLine;
    if (!isOnline) {
      this.$root.$emit("alert", [
        "Alert",
        "There might be an internet connection issue on your system",
      ]);
      return;
    }
    this.profile = this.initialProfileData;
    this.generateUpdatedProfile();
  },
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    isSmall() {
      return this.screenWidth < 600;
    },
    classes() {
      return {
        container: this.breakPointValues(["pa-0", "pa-0", "", ""]),
        headers: this.breakPointValues(["mb-2", "mb-5", "mb-5", "mb-6"]),
        editButton: this.readOnly ? "" : "inert invisible",
        profileCard: this.breakPointValues([
          "px-3 py-3",
          "px-5 py-6",
          "px-8 py-9",
          "px-8 py-9",
        ]),
        profileBox: this.breakPointValues(["pa-2", "pa-5", "pa-5", "pa-5"]),
        avatar: {
          true: "",
          false:
            "white--text ft-bold xl:text-5xl lg:text-3xl md:text-3xl sm:text-2xl",
        },
      };
    },
    valid() {
      return this.$refs.phoneNumberField.validate();
    },
    gvalid() {
      return this.$refs.gphonfield.validate();
    },
    emailValid() {
      return this.$refs.gemailfield.validate();
    },
    cols() {
      return {
        profile: {
          left: this.breakPointValues(["5", "5", "4", "4"]),
          right: this.breakPointValues(["7", "7", "8", "8"]),
        },
        info: {
          left: this.breakPointValues(["2", "2", "1", "1"]),
          right: this.breakPointValues(["10", "10", "10", "10"]),
        },
      };
    },
    sizes() {
      return {
        avatar: {
          height: this.breakPointValues([100, 100, 100, 100]),
          width: this.breakPointValues([100, 100, 100, 100]),
        },
        editButton: {
          height: 20,
          width: 20,
          min: 20,
        },
      };
    },
    initials() {
      let words = this.profile.name.split(" ");
      return words
        .map((word) => word.charAt(0))
        .slice(0, 2)
        .join("")
        .toUpperCase();
    },
    avatarDisplayCondition() {
      return (
        this.isExist(this.updatedProfileData, "avatar_link") || this.localAvatar
      );
    },
    alertMessage() {
      return this.$language
        ? "প্রদত্ব তথ্য সঠিক হতে হবে"
        : "Correctly fill out the fields";
    },
  },
  // watch: {
  //   guardianForm(value) {
  //     if (value) this.showFormAlertMessage = false;
  //   }
  // },
  methods: {
    breakPointValues(values) {
      let s = this.screenWidth;
      return s < 600
        ? values[0]
        : s < 960
        ? values[1]
        : s < 1903
        ? values[2]
        : values[3];
    },
    isExist(object, key) {
      return object[key] === undefined
        ? false
        : object[key].length === 0
        ? false
        : true;
    },
    generateUpdatedProfile() {
      let hasAvatar =
        this.profile.avatar_link === undefined
          ? false
          : this.profile.avatar_link.length === 0
          ? false
          : true;

      let hasAddress =
        this.profile.address === undefined
          ? false
          : this.profile.address.length === 0
          ? false
          : true;
      // let hasInstitution =
      //   this.profile.institution === undefined
      //     ? false
      //     : this.profile.institution.length === 0
      //     ? false
      //     : true;

      // let hasGuardian =
      //   this.profile.guardians === undefined
      //     ? false
      //     : this.profile.guardians.length === 0
      //     ? false
      //     : true;

      this.updatedProfileData.avatar_link = hasAvatar
        ? this.profile.avatar_link
        : undefined;
      this.updatedProfileData["name"] = this.profile.name;
      this.updatedProfileData.phone = this.profile.phone;
      this.updatedProfileData.address = hasAddress
        ? this.profile.address
        : undefined;
      // this.updatedProfileData.institution = hasInstitution
      //   ? this.profile.institution
      //   : undefined;
      // this.updatedProfileData.guardians = hasGuardian
      //   ? this.profile.guardians
      //   : [];
      // this.guardian = hasGuardian
      //   ? JSON.parse(JSON.stringify(this.updatedProfileData.guardians[0]))
      //   : this.guardian;
    },
    makeFormEditable() {
      this.readOnly = false;
    },
    makeReadOlny() {
      this.readOnly = true;
    },
    changeAvatar() {
      this.$refs.file.click();
    },
    loadImageFile(filelist) {
      const file = filelist[0];
      if (file.size > 10 * 1024 * 1024) {
        this.$root.$emit("alert", [
          undefined,
          "Image size must not be more than 10MB",
        ]);
      } else {
        this.localAvatar = file;
      }
    },
    removeAvatar() {
      if (this.localAvatar) this.localAvatar = undefined;
      if (this.updatedProfileData.avatar_link || this.profile.avatar_link) {
        this.updatedProfileData.avatar_link = "";
        this.clearAvatar = true;
      }
    },
    getImageURL() {
      if (this.localAvatar) {
        return URL.createObjectURL(this.localAvatar);
      } else {
        return this.updatedProfileData.avatar_link;
      }
    },

    cancelSave() {
      this.readOnly = true;
      if (this.localAvatar) this.localAvatar = undefined;
      this.clearAvatar = false;
      this.generateUpdatedProfile();
    },

    async updateProfileInfo() {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system",
        ]);
        return;
      }
      // if (!this.guardianForm) {
      //   if (!this.showFormAlertMessage) this.showFormAlertMessage = true;
      //   else {
      //     await this.$refs.banner.flash();
      //   }
      //   return;
      // }
      let outgoingData = _.cloneDeep(this.updatedProfileData);
      if (!this.valid) {
        this.$root.$emit("alert", [undefined, "Phone number is not valid"]);
        return;
      }

      if (this.updatingProfile) return;
      else this.updatingProfile = true;

      Object.keys(outgoingData).forEach((key) => {
        if (outgoingData[key] === null || outgoingData[key] === undefined) {
          delete outgoingData[key];
        }
      });
      if (
        outgoingData.address &&
        outgoingData.address.length > this.aboutTextMaxString
      ) {
        this.$root.$emit("alert", [
          undefined,
          "Address must be in 500 character",
        ]);
        return;
      }
      // if (
      //   outgoingData.institution &&
      //   outgoingData.institution.length > this.institutionMaxString
      // ) {
      //   this.$root.$emit("alert", [
      //     undefined,
      //     "Institution must be in 200 character"
      //   ]);
      //   return;
      // }
      if (this.localAvatar) {
        this.$set(outgoingData, "file_name", this.localAvatar.name);
      } else if (this.clearAvatar) {
        this.$set(outgoingData, "file_name", "");
      }
      this.$delete(outgoingData, "avatar_link");
      // let isGuardianAdded = false;
      // let gv = Object.values(this.guardian);
      // for (let index = 0; index < gv.length; index++) {
      //   let element = gv[index];
      //   if (element === undefined) continue;
      //   if (element !== undefined || element.length > 0) {
      //     isGuardianAdded = true;
      //     break;
      //   }
      // }

      // if (isGuardianAdded) {
      //   if (this.guardian.number) {
      //     if (!this.gvalid) {
      //       this.$root.$emit("alert", [undefined, "Guardian Phone not valid"]);
      //       return;
      //     }
      //   }
      //   if (this.guardian.email) {
      //     if (!this.emailValid) {
      //       this.$root.$emit("alert", [undefined, "Guardian Email not valid"]);
      //       return;
      //     }
      //   }

      //   Object.keys(this.guardian).forEach(key => {
      //     if (
      //       this.guardian[key] === null ||
      //       this.guardian[key] === "" ||
      //       this.guardian[key] === undefined
      //     ) {
      //       delete this.guardian[key];
      //     }
      //   });
      //   outgoingData.guardians[0] = this.guardian;
      // }

      try {
        let resp = await this.$store.dispatch("userAF/updateTeacherProfile", {
          profile: outgoingData,
        });
        if ("status" in resp) {
          if (resp.status.message === "success") {
            this.$emit("save");
            this.$root.$emit("alert", ["Success", "Saved"]);
            this.readOnly = true;
            await this.getProfileInfo();
            return;
          }
          if (resp.status.message === "deleted") {
            this.$emit("save");
            this.$root.$emit("alert", ["Success", "Image Deleted and Saved"]);
            this.readOnly = true;
            await this.getProfileInfo();
            return;
          }
        } else {
          await this.$store.dispatch("userAF/updateAvatar", {
            policy: resp.info,
            fileBlob: this.localAvatar,
          });
          this.$emit("save");
          this.readOnly = true;
          await this.getProfileInfo();
          this.$root.$emit("alert", ["Success", "Saved"]);
        }
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.updatingProfile = false;
      }
    },
    async getProfileInfo() {
      try {
        this.profile = await this.$store.dispatch("userAF/getStudentProfile", {
          type: "retrieve",
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
  },
};
</script>

<style scoped>
.acc-font {
  font-size: 12px;
  font-weight: 400;
  color: #272727;
}
.acc-header {
  font-size: 12px;
  font-weight: 500;
  color: #272727;
}

.name-input:focus {
  outline: none;
}

.avatar-edit-button {
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(3px);
  color: black;
  border-radius: 75%;
  position: absolute;
  bottom: 0;
}
</style>
