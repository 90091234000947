<template>
  <v-container id="main" v-if="renderNow">
    <v-row no-gutters>
      <v-col>
        <student-account-details-form
          :initialProfileData="profileData"
        ></student-account-details-form>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-6">
      <v-col>
        <student-security-options
          :passUpDate="profileData.password_updated_at"
        ></student-security-options>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-6">
      <v-col>
        <delete-account-component></delete-account-component>
      </v-col>
    </v-row>
  </v-container>
  <v-container
    v-else
    style="height: 40vh"
    class="d-flex justify-center align-center"
  >
    <v-progress-circular color="primary" indeterminate></v-progress-circular>
  </v-container>
</template>

<script>
import StudentAccountDetailsForm from "./StudentAccountDetailsForm.vue";
import StudentSecurityOptions from "./StudentSecurityOptions.vue";
import DeleteAccountComponent from "#af/user/views/components/DeleteAccountComponent";

export default {
  components: {
    StudentAccountDetailsForm,
    StudentSecurityOptions,
    DeleteAccountComponent,
  },
  name: "StudentAccountDetails",
  data() {
    return {
      profileData: undefined,
      renderNow: false,
    };
  },
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    cols() {
      return {
        left: this.breakPointValues(["12", "12", "8", "6"]),
        right: this.breakPointValues(["12", "12", "4", "6"]),
      };
    },
  },
  async created() {
    await this.getProfileInfo();
  },
  methods: {
    breakPointValues(values) {
      let s = this.screenWidth;
      return s < 600
        ? values[0]
        : s < 960
        ? values[1]
        : s < 1903
        ? values[2]
        : values[3];
    },

    async getProfileInfo() {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system",
        ]);
        return;
      }
      try {
        this.profileData = await this.$store.dispatch(
          "userAF/getStudentProfile",
          { type: "retrieve" }
        );
        this.renderNow = true;
      } catch (e) {
        this.$root.$emit("alert", ["Alert", e.message]);
      }
    },
  },
};
</script>

<style></style>
