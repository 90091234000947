var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "d-flex bg-white rounded",
      class: [
        _vm.$vuetify.breakpoint.width < 1100 ? "py-6 px-4" : "py-9 px-6",
        _vm.editPassword ? "flex-column" : "flex-row align-center",
      ],
      staticStyle: { gap: "16px" },
    },
    [
      _c(
        "h3",
        {
          class: _vm.editPassword ? "align-self-start" : "",
          staticStyle: { color: "#4c4b4b", "font-weight": "600" },
        },
        [_vm._v(" Password ")]
      ),
      !_vm.editPassword
        ? _c(
            "v-btn",
            {
              staticStyle: { color: "#676767", "text-transform": "none" },
              attrs: { outlined: "", small: _vm.isSmall },
              on: { click: _vm.showEditPassword },
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "xl:text-base xl:text-base xl:text-base xl:text-base",
                },
                [_vm._v("Change")]
              ),
            ]
          )
        : _vm._e(),
      !_vm.editPassword && _vm.updatedPasswordDuration > 0
        ? _c(
            "p",
            {
              class: [
                "xl:text-base lg:text-sm md:text-sm sm:text-xxs",
                _vm.classes.changeText,
              ],
            },
            [
              _vm._v(
                " password changed " +
                  _vm._s(_vm.updatedPasswordDuration) +
                  " days ago "
              ),
            ]
          )
        : _vm._e(),
      _vm.updatingPasswordSuccessful
        ? _c(
            "v-row",
            {
              staticClass: "mt-6",
              staticStyle: { "justify-content": "center", color: "green" },
            },
            [_vm._v(_vm._s(_vm.returnMessage["data"]) + " ")]
          )
        : _vm._e(),
      _vm.editPassword
        ? _c(
            "div",
            [
              _vm.returnMessage !== undefined
                ? _c("submission-alert-message", {
                    ref: "alertMessage",
                    staticClass: "mb-4",
                    attrs: { message: _vm.returnMessage["data"] },
                    model: {
                      value: _vm.showGeneralMessage,
                      callback: function ($$v) {
                        _vm.showGeneralMessage = $$v
                      },
                      expression: "showGeneralMessage",
                    },
                  })
                : _c("submission-alert-message", {
                    ref: "alertMessage",
                    staticClass: "mb-4",
                    attrs: { message: _vm.badSubmissionMessage },
                    model: {
                      value: _vm.showGeneralMessage,
                      callback: function ($$v) {
                        _vm.showGeneralMessage = $$v
                      },
                      expression: "showGeneralMessage",
                    },
                  }),
              _c(
                "div",
                {
                  class: _vm.$vuetify.breakpoint.width < 1200 ? "" : "d-flex",
                  style:
                    _vm.$vuetify.breakpoint.width > 1100 ? "gap: 24px" : "",
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "min-width": "32%" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "ml-0 mt-0",
                          staticStyle: {
                            "font-size": "12px",
                            "font-weight": "500",
                          },
                          attrs: { "no-gutters": "" },
                        },
                        [_vm._v(" Old Password ")]
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-1", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                ref: "oldPasswordField",
                                attrs: {
                                  dense: "",
                                  "single-line": "",
                                  solo: "",
                                  outlined: "",
                                  flat: "",
                                  height: "42px",
                                  label: "Old Password",
                                  placeholder: "Enter your old password....",
                                  rules: [
                                    (v) => _vm.required(v, "old Password"),
                                  ],
                                  type: "password",
                                },
                                model: {
                                  value: _vm.oldPassword,
                                  callback: function ($$v) {
                                    _vm.oldPassword = $$v
                                  },
                                  expression: "oldPassword",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "min-width": "32%" } },
                    [
                      _c(
                        "v-row",
                        {
                          class:
                            _vm.$vuetify.breakpoint.width < 1100 ? "mt-5" : "",
                          staticStyle: {
                            "font-size": "12px",
                            "font-weight": "500",
                          },
                          attrs: { "no-gutters": "" },
                        },
                        [_vm._v("New Password")]
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-1", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                ref: "newpasswordField",
                                attrs: {
                                  dense: "",
                                  "single-line": "",
                                  solo: "",
                                  flat: "",
                                  outlined: "",
                                  height: "42px",
                                  label: "New Password",
                                  placeholder: "Enter your new password....",
                                  rules: [
                                    (v) => _vm.required(v, "new password"),
                                    _vm.passwordValidation,
                                    (v) =>
                                      v !== this.oldPassword ||
                                      "Old password and new password cannot be the same",
                                  ],
                                  type: "password",
                                },
                                model: {
                                  value: _vm.newPassword,
                                  callback: function ($$v) {
                                    _vm.newPassword = $$v
                                  },
                                  expression: "newPassword",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "min-width": "32%" } },
                    [
                      _c(
                        "v-row",
                        {
                          class:
                            _vm.$vuetify.breakpoint.width < 1100 ? "mt-5" : "",
                          staticStyle: {
                            "font-size": "12px",
                            "font-weight": "500",
                          },
                          attrs: { "no-gutters": "" },
                        },
                        [_vm._v("Re-enter New Password")]
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-1", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                ref: "confNewPasswordField",
                                staticClass: "text-sm",
                                attrs: {
                                  dense: "",
                                  "single-line": "",
                                  solo: "",
                                  flat: "",
                                  outlined: "",
                                  height: "42px",
                                  label: "Confirm Password",
                                  placeholder: "Re-Enter your new password....",
                                  rules: _vm.confNewPasswordRules,
                                  type: "password",
                                },
                                model: {
                                  value: _vm.confNewPassword,
                                  callback: function ($$v) {
                                    _vm.confNewPassword = $$v
                                  },
                                  expression: "confNewPassword",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("div", { staticClass: "mb-4" }, [
                _c("p", [
                  _vm._v(
                    "Your new password must have atleast 8 characters(mix of lower/upper case) and include a number and a symbol "
                  ),
                ]),
              ]),
              _c(
                "div",
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "start", "no-gutters": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text px-6 py-3",
                          staticStyle: { "text-transform": "none" },
                          attrs: {
                            color: "#0099DC",
                            loading: _vm.updatingPassword,
                            elevation: "0",
                          },
                          on: { click: _vm.updatePassword },
                        },
                        [_vm._v("Save")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-3 black--text",
                          staticStyle: {
                            "text-transform": "none",
                            "border-color": "#d6d6d6",
                          },
                          attrs: { outlined: "" },
                          on: { click: _vm.cancelEditPassword },
                        },
                        [_vm._v("Cancel")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }