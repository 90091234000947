<template>
  <v-container
    :class="[($vuetify.breakpoint.width < 1100 ? 'py-6 px-4' : 'py-9 px-6'), (editPassword ? 'flex-column' : 'flex-row align-center')]"
    class="d-flex bg-white rounded" style="gap: 16px">
    
    <h3 style="color: #4c4b4b; font-weight: 600" :class="editPassword ? 'align-self-start' : ''">
      Password
    </h3>
    
    <v-btn outlined :small="isSmall" style="color: #676767; text-transform: none" @click="showEditPassword"
      v-if="!editPassword">
      <span class="xl:text-base xl:text-base xl:text-base xl:text-base">Change</span>
    </v-btn>

    <p v-if="!editPassword && updatedPasswordDuration > 0" :class="[
      'xl:text-base lg:text-sm md:text-sm sm:text-xxs',
      classes.changeText,
    ]">
      password changed {{ updatedPasswordDuration }} days ago
    </p>

    <v-row v-if="updatingPasswordSuccessful" class="mt-6" style="justify-content: center; color: green">{{
      returnMessage["data"] }}
    </v-row>
    <!-- <v-row class="mt-3" :no-gutters="!isSmall" > -->
    <div v-if="editPassword">
      <submission-alert-message class="mb-4" v-if="returnMessage !== undefined" :message="returnMessage['data']"
        v-model="showGeneralMessage" ref="alertMessage"></submission-alert-message>
      <submission-alert-message v-else class="mb-4" :message="badSubmissionMessage" v-model="showGeneralMessage"
        ref="alertMessage"></submission-alert-message>

      <div :class="$vuetify.breakpoint.width < 1200? '' : 'd-flex'"
        :style="$vuetify.breakpoint.width > 1100? 'gap: 24px' : ''">
        <div style="min-width: 32%;">
          <v-row style="font-size: 12px; font-weight: 500;" no-gutters class="ml-0 mt-0">
            Old Password
          </v-row>
          <v-row no-gutters class="mt-1">
            <v-col >
              <v-text-field dense single-line solo outlined flat height="42px" label="Old Password"
                placeholder="Enter your old password...." v-model="oldPassword"
                :rules="[(v) => required(v, 'old Password')]" type="password" ref="oldPasswordField"></v-text-field>
            </v-col>
          </v-row>
        </div>

        <div style="min-width: 32%;" >
          <v-row style="font-size: 12px; font-weight: 500;" no-gutters
            :class="$vuetify.breakpoint.width < 1100 ? 'mt-5' : ''">New Password</v-row>
          <v-row no-gutters class="mt-1">
            <v-col >
              <v-text-field dense single-line solo flat outlined height="42px" label="New Password"
                placeholder="Enter your new password...." v-model="newPassword" :rules="[
                  (v) => required(v, 'new password'),
                  passwordValidation,
                  (v) =>
                    v !== this.oldPassword ||
                    'Old password and new password cannot be the same',
                ]" type="password" ref="newpasswordField"></v-text-field>
            </v-col>
          </v-row>
        </div>

        <div style="min-width: 32%;">
          <v-row style="font-size: 12px; font-weight: 500;" no-gutters
            :class="$vuetify.breakpoint.width < 1100 ? 'mt-5' : ''">Re-enter New Password</v-row>
          <v-row no-gutters class="mt-1">
            <v-col >
              <v-text-field dense single-line solo flat class="text-sm" outlined height="42px" label="Confirm Password"
                placeholder="Re-Enter your new password...." v-model="confNewPassword" :rules="confNewPasswordRules"
                type="password" ref="confNewPasswordField"></v-text-field>
            </v-col>
          </v-row>
        </div>
      </div>

      <div class="mb-4">
        <p>Your new password must have atleast 8 characters(mix of lower/upper case) and include a number and a symbol
        </p>
      </div>

      <div>
        <v-row justify="start" no-gutters>
          <v-btn color="#0099DC" class="white--text px-6 py-3" @click="updatePassword" :loading="updatingPassword"
            style="text-transform: none" elevation="0">Save</v-btn>
          <v-btn outlined class="ml-3 black--text" @click="cancelEditPassword"
            style="text-transform: none; border-color: #d6d6d6">Cancel</v-btn>
        </v-row>
      </div>
    </div>

    <!-- <v-row v-if="isTeacher">
      <v-col cols="12">
        <h3
          style="color: #4c4b4b"
          class="xl:text-xl lg:text-xl md:text-base sm:text-sm"
        >
          Connect Social Media
        </h3>
        <v-row>
          <v-col class="d-flex flex-column">
            <v-btn icon>
              <img :src="icons.addSocialIcon" alt="" />
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import AddSocialIcon from "@af/modules/user/assets/icons/add_social_icon.svg";
import authService from "@af/modules/auth/services/AuthService";
import { FieldValidations } from "/global/utils/validations.js";
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage.vue";

export default {
  name: "StudentSecurityOptions",
  components: { SubmissionAlertMessage },
  props: {
    passUpDate: { type: String, required: false },
  },
  mixins: [FieldValidations],
  data() {
    return {
      user: undefined,
      key: 1,
      renderNow: false,
      returnMessage: undefined,
      editPassword: false,
      oldPassword: undefined,
      newPassword: undefined,
      isSent: false,
      confNewPassword: undefined,
      confNewPasswordRules: [
        (v) => this.required(v, "confirmation password"),
        (v) => this.passwordMatch(v, this.newPassword),
      ],
      oldPasswordMismatch: false,
      updatingPassword: false,
      updatingPasswordFailed: false,
      updatingPasswordSuccessful: false,
      verifyingEmail: false,
      showGeneralMessage: false,
    };
  },
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    isSmall() {
      return this.screenWidth < 600;
    },
    userFromStore() {
      return this.$store.getters[this.$store.$types.GET_USER_DETAILS];
    },
    badSubmissionMessage() {
      return "Must validate all mandatory fields";
    },
    updatedPasswordDuration() {
      if (this.passUpDate === undefined) {
        return 0;
      }
      let d = new Date(this.passUpDate);
      let curr = new Date();
      return this.datediff(d, curr);
    },
    isTeacher() {
      if (this.userFromStore.authorized_views.includes("TEACHER_VIEW")) {
        return true;
      } else {
        return false;
      }
    },
    classes() {
      return {
        container: this.breakPointValues(["pa-0", "pa-0", "pt-12", "pt-15"]),
        divCard: this.breakPointValues(["py-3 px-3", "py-6 px-5", "", ""]),
        buttonText: this.breakPointValues(["pb-2", "pb-2", "pb-5", "pb-5"]),
        changeText: this.breakPointValues(["pt-2", "pt-2", "pt-5", "pt-5"]),
      };
    },
    sizes() {
      return {
        securityCard: {
          width: this.breakPointValues(["100%", "80%", "80%", "48%"]),
        },
      };
    },
    icons() {
      return {
        addSocialIcon: AddSocialIcon,
      };
    },
    valid() {
      return (
        this.$refs.oldPasswordField.validate() &&
        this.$refs.newpasswordField.validate() &&
        this.$refs.confNewPasswordField.validate()
      );
    },
  },
  watch: {
    valid: {
      handler(value) {
        if (value) {
          this.showGeneralMessage = false;
        }
      },
    },
  },
  methods: {
    breakPointValues(values) {
      let s = this.screenWidth;
      return s < 600
        ? values[0]
        : s < 961
          ? values[1]
          : s < 1903
            ? values[2]
            : values[3];
    },
    showEditPassword() {
      this.editPassword = true;
      this.updatingPasswordSuccessful = false;
      this.oldPassword = "";
      this.newPassword = "";
    },
    cancelEditPassword() {
      this.editPassword = false;
      this.newPassword = undefined;
      this.confNewPassword = undefined;
      this.updatingPasswordSuccessful = false;
      // this.closeMessage();
    },
    findLinkType(url) { },
    async updatePassword() {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system",
        ]);
        return;
      }
      this.returnMessage = undefined;
      if (this.valid) {
        this.oldPasswordMismatch = false;
        this.updatingPasswordSuccessful = false;
        this.updatingPasswordFailed = false;
        if (this.isSent) {
          return;
        }
        try {
          this.isSent = true;
          this.updatingPassword = true;
          this.returnMessage = await this.$store.dispatch(
            this.$store.$types.UPDATE_PASSWORD,
            {
              old_password: this.oldPassword,
              new_password: this.newPassword,
            }
          );
          this.isSent = false;
          this.editPassword = false;
          this.updatingPasswordSuccessful = true;
          this.oldPassword = undefined;
          this.newPassword = undefined;
          this.confNewPassword = undefined;
        } catch (e) {
          this.isSent = true;
          this.returnMessage = e.response;
          if (e.response.status === 401) {
            this.oldPasswordMismatch = true;
            this.showGeneralMessage = true;
          } else {
            this.updatingPasswordFailed = true;
            this.showGeneralMessage = true;
          }
          this.isSent = false;
        } finally {
          this.updatingPassword = false;
        }
      } else {
        if (this.showGeneralMessage) {
          this.$refs.alertMessage.flash();
        }
        this.showGeneralMessage = true;
      }
    },
    async verifyEmail() {
      try {
        this.verifyingEmail = "sending";
        await this.$store.dispatch("user/sendVerifyLink");
        this.verifyingEmail = "sent";
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          "Failed to send verify link. Please try again later.",
        ]);
        this.verifyingEmail = "unsent";
      }
    },
    async getUserInfo() {
      this.user = await authService.getUser(this.$store.state.userAF.authToken);
      this.renderNow = true;
    },
    datediff(first, second) {
      return Math.round((second - first) / (1000 * 60 * 60 * 24));
    },
  },
};
</script>

<style lang="scss" scoped>
.mk-education-console .v-card {
  box-shadow: none !important;
  border-radius: 7px;
}

.bg-white {
  background-color: white;
}

.pt-15 {
  padding-top: 60px;
}
</style>
