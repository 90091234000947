<template>
  <v-container>
    <p
      class="mb-12 ml-9 mt-2 dark--text font-weight-bold text-h5"
    >
      My Account
    </p>
    <div>
      <div class="mr-3 ml-3 mb-0" v-if="!isTeacher">
        <v-tabs
          v-if="screenWidth >= 700"
          v-model="tab"
          class="tab-bar"
          hide-slider
          background-color="transparent"
          :show-arrows="true"
        >
          <v-tab
            v-for="(item, i) in tabItems"
            :key="i"
            class="single-tab-item text-h6 py-3 px-8 xl:text-xl lg:text-xl"
            active-class="selected-tab text-h6 py-3 px-8"
            :style="{ cursor: isTabDisabled(item) ? 'not-allowed' : 'pointer' }"
            @click="currentTab = item.title"
            :disabled="isTabDisabled(item)"
          >
            {{ item.title }}
          </v-tab>
        </v-tabs>
        <v-select
          v-else
          v-model="currentTab"
          :items="selectItems"
          background-color="white"
          outlined
          class="selectOptions"
          item-disabled="disable"
          :menu-props="{ rounded: 'sm' }"
        ></v-select>
      </div>
      <div class="">
        <teacher-account-details v-if="isTeacher"></teacher-account-details>
        <student-account-details
          v-else-if="isStudent && currentTab === 'Account'"
        ></student-account-details>
        <Subscriptions
          v-else-if="isStudent && currentTab === 'Subscriptions'"
        />
        <Transactions v-else-if="isStudent && currentTab === 'Transactions'" />
      </div>
    </div>
  </v-container>
</template>

<script>
import TeacherAccountDetails from "./teacheraccount/TeacherAccountDetails.vue";
import StudentAccountDetails from "./studentaccount/StudentAccountDetails.vue";
import Subscriptions from "#ef/payment-transaction/components/Subscriptions.vue";
import Transactions from "#ef/payment-transaction/components/Transactions.vue";

export default {
  name: "AccountDetails",
  components: {
    TeacherAccountDetails,
    StudentAccountDetails,
    Subscriptions,
    Transactions,
  },
  data() {
    return {
      currentTab: "Account",
      isDisabled: true,
      tabItems: [
        { title: "Account", component: "Account", disable: false },
        { title: "Subscriptions", component: "Subscriptions", disable: false },
        // { title: "Transactions", component: "Transactions", disable: true },
        // { title: "Saved cards", component: "SavedCards", disable: true },
      ],
    };
  },
  methods: {
    isTabDisabled(item) {
      return (
        (item.title === "Transactions" && this.isDisabled) ||
        (item.title === "Saved cards" && this.isDisabled)
      );
    },
  },
  computed: {
    userFromStore() {
      return this.$store.getters[this.$store.$types.GET_USER_DETAILS];
    },
    isTeacher() {
      if (this.userFromStore.authorized_views.includes("TEACHER_VIEW")) {
        return true;
      } else {
        return false;
      }
    },
    isStudent() {
      if (this.userFromStore.authorized_views.includes("STUDENT_VIEW")) {
        return true;
      } else {
        return false;
      }
    },

    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    selectItems() {
      return this.tabItems.map((item) => ({
        text: item.title,
        value: item.title,
        disable: item.disable,
      }));
    },
    classes() {
      return {
        main: `pa-${this.breakPointValues(4, 4, 6, 8)} pb-0 ml-0`,
        title: `mb-${this.breakPointValues(4, 4, 8, 8)}`,
        content: `tab-content-${this.breakPointValues("sm", "md", "lg", "xl")}`,
      };
    },
  },
  created() {
    if (this.$route.query.redirectTo) {
      let transactionId = this.$route.query.id;
      let transactionType = this.$route.query.type;
      let transactionStatus = this.$route.query.status;
      if (transactionId && transactionType && transactionStatus) {
        if (
          !["cancel", "aborted", "failure", "failed"].includes(
            transactionStatus
          )
        ) {
          window.location.href = this.$route.query.redirectTo;
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.tab-bar {
  border-bottom: 1px solid #0099dc;
  // margin-inline: 10px;
}

.single-tab-item {
  text-transform: none !important;
  color: #272727 !important;
  opacity: 1;
}

.selected-tab {
  background-color: #d6f3ff;
  opacity: 1;
  border-radius: 4px 4px 0px 0px;
}

.tab-content-xl {
  width: 1024px;
}

.tab-content-md {
  width: min(100%, 1024px);
}

.tab-content-lg {
  width: min(100%, 800px);
}

.tab-content-sm {
  width: 100%;
}

.selectOptions {
  font-size: 15px;
  font-weight: 400;
  text-align: center;
}
</style>
