var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "bg-white rounded",
      class: _vm.$vuetify.breakpoint.width < 1100 ? "py-6 px-4" : "py-9 px-6",
    },
    [
      _c("div", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.phase == 0,
                expression: "phase == 0",
              },
            ],
          },
          [
            _c(
              "h3",
              {
                class: [
                  _vm.classes.buttonText,
                  "xl:text-xl lg:text-xl md:text-base sm:text-sm",
                ],
                staticStyle: { color: "#4c4b4b" },
              },
              [_vm._v(" Delete Account ")]
            ),
            _c(
              "v-btn",
              {
                staticClass: "white--text",
                attrs: {
                  small: _vm.isSmall,
                  "no-gutters": "",
                  elevation: "0",
                  color: "#FF5B5B",
                  width: "166px",
                },
                on: {
                  click: function ($event) {
                    _vm.phase = 1
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "xl:text-base xl:text-base xl:text-base xl:text-base",
                  },
                  [_vm._v("Delete Account")]
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.phase == 1,
                expression: "phase == 1",
              },
            ],
          },
          [
            _c(
              "h3",
              {
                class: [
                  _vm.classes.buttonText,
                  "decline--text xl:text-xl lg:text-xl md:text-base sm:text-sm",
                ],
              },
              [_vm._v(" Do You Want To Delete Your Account? ")]
            ),
            _c(
              "p",
              { staticClass: "xl:text-base lg:text-sm md:text-sm sm:text-xxs" },
              [
                _vm._v(
                  " Please enter your password to confirm the deactivation of your account "
                ),
              ]
            ),
            _c(
              "v-card",
              {
                staticClass: "mt-5",
                attrs: {
                  flat: "",
                  color: "transparent",
                  width: _vm.sizes.card.width,
                },
              },
              [
                _vm.userAuthProvider.toLowerCase() === "password"
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-row",
                          {
                            staticClass: "ml-0 mt-0",
                            staticStyle: {
                              "font-size": "12px",
                              "font-weight": "500",
                            },
                            attrs: { "no-gutters": "" },
                          },
                          [_vm._v(" Current Password ")]
                        ),
                        _c(
                          "v-row",
                          { staticClass: "mt-1", attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-form",
                                  {
                                    ref: "form",
                                    model: {
                                      value: _vm.valid,
                                      callback: function ($$v) {
                                        _vm.valid = $$v
                                      },
                                      expression: "valid",
                                    },
                                  },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        dense: "",
                                        "single-line": "",
                                        solo: "",
                                        outlined: "",
                                        flat: "",
                                        placeholder:
                                          "Enter your current password....",
                                        type: "password",
                                        autocomplete: "new-password",
                                        rules: [
                                          _vm.required,
                                          _vm.passwordValidation,
                                        ],
                                      },
                                      on: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          $event.preventDefault()
                                          return _vm.confirmDeleteAccount.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.password,
                                        callback: function ($$v) {
                                          _vm.password = $$v
                                        },
                                        expression: "password",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-row",
                  { staticClass: "pt-1", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "white--text",
                        attrs: {
                          loading: _vm.deactivating,
                          color: "#ff4848",
                          elevation: "0",
                        },
                        on: { click: _vm.confirmDeleteAccount },
                      },
                      [_vm._v("Delete ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ml-3 black--text",
                        staticStyle: { "border-color": "#d6d6d6" },
                        attrs: { outlined: "" },
                        on: {
                          click: function ($event) {
                            _vm.phase = 0
                          },
                        },
                      },
                      [_vm._v(" Cancel ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }