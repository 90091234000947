var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { staticClass: "overflow-hidden pa-0 ma-0" }, [
    _c(
      "div",
      {
        staticClass: "pa-5 mt-4 bg-white rounded",
        attrs: { outlined: !_vm.readOnly ? true : false },
      },
      [
        _c(
          "v-row",
          {
            class: _vm.$vuetify.breakpoint.width < 1100 ? "flex-column" : "",
            staticStyle: { gap: "8px" },
          },
          [
            _c(
              "v-col",
              [
                _c(
                  "v-card",
                  {
                    staticClass:
                      "d-flex justify-center align-center pa-3 pa-sm-5",
                    attrs: { color: "#E5F4FB", "min-height": "190", flat: "" },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: _vm.$vuetify.breakpoint.smAndDown
                          ? "d-flex flex-column justify-center align-center"
                          : "d-flex",
                        staticStyle: { gap: "16px !important" },
                      },
                      [
                        _c(
                          "v-avatar",
                          {
                            class:
                              _vm.classes.avatar[
                                _vm.isExist(
                                  _vm.updatedProfileData,
                                  "avatar_link"
                                )
                              ],
                            attrs: {
                              "align-self-center": "",
                              color: "primary",
                              height: _vm.sizes.avatar.height,
                              width: _vm.sizes.avatar.width,
                            },
                          },
                          [
                            _vm.avatarDisplayCondition
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.getImageURL(),
                                    alt: "",
                                    width: "148",
                                    height: "148",
                                  },
                                })
                              : _c("span", [_vm._v(_vm._s(_vm.initials))]),
                            _c(
                              "v-menu",
                              {
                                attrs: { "offset-y": "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "button",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.readOnly,
                                                    expression: "!readOnly",
                                                  },
                                                ],
                                                staticClass:
                                                  "avatar-edit-button pa-1 xl:text-base lg:text-sm md:text-sm sm:text-xs",
                                              },
                                              "button",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v(" Change ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.showAvatarChageMenu,
                                  callback: function ($$v) {
                                    _vm.showAvatarChageMenu = $$v
                                  },
                                  expression: "showAvatarChageMenu",
                                },
                              },
                              [
                                _c(
                                  "v-list",
                                  { staticStyle: { padding: "0px 0px" } },
                                  [
                                    _c(
                                      "v-list-item",
                                      {
                                        staticClass:
                                          "px-3 py-0 d-flex justify-center",
                                        on: { click: _vm.changeAvatar },
                                      },
                                      [_vm._v("Upload Photo ")]
                                    ),
                                    _c("v-divider"),
                                    _c(
                                      "v-list-item",
                                      {
                                        staticClass:
                                          "px-3 py-0 d-flex justify-center",
                                        on: { click: _vm.removeAvatar },
                                      },
                                      [_vm._v("Remove ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("input", {
                              ref: "file",
                              staticStyle: { display: "none" },
                              attrs: { type: "file", accept: "image/*" },
                              on: {
                                change: function ($event) {
                                  return _vm.loadImageFile($event.target.files)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            class: _vm.$vuetify.breakpoint.smAndDown
                              ? "d-flex flex-column justify-center align-center"
                              : "",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "xl:text-3xl lg:text-2xl md:text-xl text-base text-truncate",
                                class: _vm.$vuetify.breakpoint.smAndDown
                                  ? "mt-2"
                                  : "",
                                staticStyle: {
                                  color: "#29637d",
                                  "max-height": "65px !important",
                                },
                                style: `max-width: ${
                                  _vm.$vuetify.breakpoint.smAndDown ? 200 : 370
                                }px;`,
                              },
                              [_vm._v(" " + _vm._s(_vm.profile.name) + " ")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ft-medium xl:text-base lg:text-base md:text-base sm:text-sm mt-2",
                              },
                              [_vm._v(" Student ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "px-8",
                                    class: _vm.classes.editButton,
                                    staticStyle: {
                                      "text-transform": "none",
                                      color: "#0099dc",
                                      padding: "none",
                                      "border-color": "#0099dc",
                                    },
                                    attrs: { outlined: "", small: "" },
                                    on: { click: _vm.makeFormEditable },
                                  },
                                  [_vm._v(" Edit ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "v-col",
              [
                _c(
                  "v-col",
                  { staticClass: "pa-0 ma-0" },
                  [
                    _c("p", { staticClass: "acc-header" }, [
                      _vm._v("Email Address"),
                    ]),
                    _c("v-text-field", {
                      staticClass: "acc-font mt-2",
                      attrs: {
                        readonly: "",
                        height: "46px",
                        outlined: "",
                        dense: "",
                        value: _vm.profile.email,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "pa-0 ma-0" },
                  [
                    _c("p", { staticClass: "acc-header" }, [
                      _vm._v("Phone Number"),
                    ]),
                    _c("v-text-field", {
                      ref: "phoneNumberField",
                      staticClass: "acc-font mt-2",
                      attrs: {
                        readonly: _vm.readOnly,
                        "hide-details": _vm.isSmall,
                        dense: "",
                        outlined: "",
                        height: "46px",
                        placeholder: _vm.profile.phone,
                        rules: [_vm.phoneValidation],
                      },
                      model: {
                        value: _vm.updatedProfileData.phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.updatedProfileData, "phone", $$v)
                        },
                        expression: "updatedProfileData.phone",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-row",
          { staticClass: "mt-0" },
          [
            _c(
              "v-col",
              [
                _c("p", { staticClass: "acc-header" }, [_vm._v("Address")]),
                _c("v-textarea", {
                  staticClass: "acc-font mt-2",
                  attrs: {
                    readonly: _vm.readOnly,
                    outlined: "",
                    dense: "",
                    placeholder: _vm.readOnly
                      ? "N/A"
                      : _vm.isExist(_vm.updatedProfileData, "address")
                      ? _vm.updatedProfileData.address
                      : "Type your present address here...",
                    "hide-details": _vm.isSmall,
                    "on-resize": "",
                    rows: "3",
                    counter: _vm.aboutTextMaxString,
                  },
                  model: {
                    value: _vm.updatedProfileData.address,
                    callback: function ($$v) {
                      _vm.$set(_vm.updatedProfileData, "address", $$v)
                    },
                    expression: "updatedProfileData.address",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        !_vm.readOnly
          ? _c(
              "v-row",
              { staticClass: "pl-3", attrs: { justify: "start" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "white--text px-6 py-3",
                    staticStyle: {
                      "text-transform": "none",
                      "box-shadow": "none",
                    },
                    attrs: { color: "#0099DC", loading: _vm.updatingProfile },
                    on: {
                      click: function ($event) {
                        return _vm.updateProfileInfo()
                      },
                    },
                  },
                  [_vm._v("Save ")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "red--text",
                    staticStyle: {
                      "text-transform": "none",
                      "border-color": "#d6d6d6",
                      "background-color": "#fff",
                      "margin-left": "10px",
                      "box-shadow": "none",
                    },
                    attrs: { outlined: "" },
                    on: { click: _vm.cancelSave },
                  },
                  [_vm._v(" Cancel ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }