<template>
  <v-container :class="classes.container">
    <v-card flat :class="classes.profileCard">
      <h2
        style="color: #4c4b4b"
        :class="[
          'xl:text-2xl lg:text-2xl md:text-xl sm:text-base',
          classes.headers
        ]"
      >
        Account Details
      </h2>
      <v-card flat color="#E5F4FB" :class="classes.profileBox">
        <v-row justify="start">
          <v-col
            :cols="cols.profile.left"
            class="d-flex align-center justify-center"
          >
            <v-avatar
              color="primary"
              :class="classes.avatar[existsOrHasValue(outgoing, 'avatar')]"
              :height="sizes.avatar.height"
              :width="sizes.avatar.width"
            >
              <img
                v-if="avatarDisplayCondition"
                :src="getImageURL()"
                alt=""
                width="148"
                height="148"
              />
              <span v-else>{{ initials }}</span>
              <v-menu offset-y v-model="showAvatarChageMenu">
                <template #activator="{ on, attrs }">
                  <button
                    v-show="!readOnly"
                    class="avatar-edit-button pa-2 xl:text-base lg:text-sm md:text-sm sm:text-xs"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Change
                  </button>
                </template>
                <v-list style="padding: 0px 0px">
                  <v-list-item
                    @click="changeAvatar"
                    :class="[
                      classes.avatarMenu,
                      'd-felx justify-center md:text-sm sm:text-sm'
                    ]"
                    >Upload Photo</v-list-item
                  >
                  <v-divider></v-divider>
                  <v-list-item
                    @click="removeAvatar"
                    :class="[
                      classes.avatarMenu,
                      'd-felx justify-center md:text-sm sm:text-sm'
                    ]"
                    >Remove</v-list-item
                  >
                </v-list>
              </v-menu>
              <input
                ref="file"
                type="file"
                accept="image/*"
                style="display: none"
                @change="loadImageFile($event.target.files)"
              />
            </v-avatar>
          </v-col>
          <v-col
            :cols="cols.profile.right"
            class="d-flex flex-column justify-space-between"
          >
            <div
              style="color: #29637d"
              class="xl:text-3xl lg:text-2xl md:text-2xl sm:text-xl"
            >
              <input
                type="text"
                class="inert name-input"
                v-model="outgoing.name"
              />
            </div>
            <div
              class="ft-medium xl:text-base lg:text-base md:text-base sm:text-sm"
            >
              Teacher
            </div>
            <div>
              <v-btn
                style="
                  text-transform: none;
                  color: #0099dc;
                  padding: none;
                  border-color: #0099dc;
                "
                :class="classes.editButton"
                outlined
                small
                @click="makeFormEditable"
              >
                Edit
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-row>
        <v-col :cols="screenWidth < 600 ? '12' : '6'">
          <p class="acc-font" style="margin-bottom: none !important">
            Email Address
          </p>
          <v-text-field
            readonly
            outlined
            class="acc-font"
            :hide-details="isSmall"
            :value="profile.email"
          >
          </v-text-field>
        </v-col>
        <v-col :cols="screenWidth < 600 ? '12' : '6'">
          <p class="acc-font">Phone Number</p>
          <v-text-field
            v-model="outgoing.phone"
            outlined
            class="acc-font"
            :placeholder="
              readOnly
                ? 'N/A'
                : existsOrHasValue(outgoing, 'phone')
                ? outgoing.phone
                : 'Enter your phone number here...'
            "
            :hide-details="isSmall"
            :readonly="readOnly"
            :rules="[phoneValidation]"
            ref="phoneNumberField"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="acc-font">Present Address</p>
          <v-textarea
            v-model="outgoing.address"
            outlined
            class="acc-font"
            :placeholder="
              readOnly
                ? 'N/A'
                : existsOrHasValue(outgoing, 'address')
                ? outgoing.address
                : 'Type your present address here...'
            "
            :hide-details="isSmall"
            :readonly="readOnly"
            on-resize
            rows="2"
            :counter="aboutTextMaxString"
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="acc-font">About</p>
          <v-textarea
            v-model="outgoing.about"
            outlined
            class="acc-font"
            :placeholder="
              readOnly
                ? 'N/A'
                : existsOrHasValue(outgoing, 'about')
                ? outgoing.about
                : 'Write about yourself here...'
            "
            :hide-details="isSmall"
            :readonly="readOnly"
            on-resize
            rows="4"
            :counter="aboutTextMaxString"
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row v-if="!readOnly">
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            depressed
            color="primary"
            class="white--text mr-5"
            style="text-transform: none"
            @click="saveBio()"
            >Save</v-btn
          >
          <v-btn
            outlined
            color="grey lighten-1"
            style="text-transform: none"
            @click="cancelBio()"
            ><span class="black--text">Cancel</span></v-btn
          >
        </v-col>
      </v-row>
    </v-card>
    <br /><br />
    <v-card flat :class="classes.workCard">
      <v-row no-gutters>
        <h3 class="sm:text-sm d-flex align-center">Work Information</h3>
        <h3 class="red--text">*</h3>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!isSmall"
          v-show="!editWorkInfo"
          outlined
          small
          color="grey ligthen-1"
          style="text-transform: none"
          @click="editWorkInfo = true"
          >Edit</v-btn
        >
        <v-btn v-else icon @click="editWorkInfo = true">
          <img
            :src="icons.editIcon"
            alt=""
            :style="`width: ${sizes.editButton.width}px`"
          />
        </v-btn>
      </v-row>
      <div v-if="editWorkInfo">
        <v-row>
          <v-col cols="12">
            <v-row no-gutters>
              <p class="acc-font">Name</p>
              <p class="red--text">*</p>
            </v-row>
            <v-text-field
              :hide-details="isSmall"
              class="acc-font"
              outlined
              :rules="rules.required"
              v-model="outgoing.professional_information.name"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="screenWidth < 600 ? '12' : '6'">
            <v-row no-gutters>
              <p class="acc-font">Designation</p>
              <p class="red--text">*</p>
            </v-row>
            <v-text-field
              :hide-details="isSmall"
              class="acc-font"
              outlined
              :rules="rules.required"
              v-model="outgoing.professional_information.designation"
            >
            </v-text-field>
          </v-col>
          <v-col :cols="screenWidth < 600 ? '12' : '6'">
            <v-row no-gutters>
              <p class="acc-font">Subject</p>
              <p class="red--text">*</p>
            </v-row>
            <v-text-field
              :hide-details="isSmall"
              class="acc-font"
              outlined
              :rules="rules.required"
              v-model="outgoing.professional_information.subject"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn
              depressed
              color="primary"
              class="white--text mr-5"
              style="text-transform: none"
              @click="saveWorkInfo()"
              >Save</v-btn
            >
            <v-btn
              outlined
              color="grey lighten-1"
              style="text-transform: none"
              @click="cancelWorkInfo()"
              ><span class="black--text">Cancel</span></v-btn
            >
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row v-if="!outgoing.professional_information.na">
          <v-col :cols="cols.info.left" class="d-flex align-center">
            <img :src="icons.workspace" alt="" />
          </v-col>
          <v-col :cols="cols.info.right">
            <h4 class="xl:text-base lg:text-base md:text-sm sm:text-xs">
              {{ outgoing.professional_information.designation }} of
              {{ outgoing.professional_information.subject }}
            </h4>
            <p class="ma-0 xl:text-sm lg:text-sm md:text-xs sm:text-xxs">
              {{ outgoing.professional_information.name }}
            </p>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col class="d-flex align-center justify-center">
            No work information given
          </v-col>
        </v-row>
      </div>
    </v-card>
    <br />
    <v-card flat :class="classes.educationCard">
      <v-row no-gutters>
        <h3 class="sm:text-sm d-flex align-center">Educational Information</h3>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!isSmall"
          v-show="!editEducationInfo"
          outlined
          small
          color="grey ligthen-1"
          style="text-transform: none"
          @click="editEducationInfo = true"
          >Edit</v-btn
        >
        <v-btn v-else icon @click="editEducationInfo = true">
          <img
            :src="icons.editIcon"
            alt=""
            :style="`width: ${sizes.editButton.width}px`"
          />
        </v-btn>
      </v-row>
      <div v-for="(item, idx) in outgoing.educational_information" :key="idx">
        <div v-if="editEducationInfo">
          <v-row>
            <v-col class="d-flex align-center justify-end"
              ><v-btn
                v-if="!isSmall"
                small
                outlined
                color="red lighten-2"
                @click="deleteEducationInformation(idx)"
                >Delete</v-btn
              >
              <v-btn v-else icon @click="deleteEducationInformation(idx)">
                <img
                  :src="icons.deleteIcon"
                  alt=""
                  :style="`width: ${sizes.editButton.width}px`"
                />
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p class="acc-font">Name</p>
              <v-text-field
                :hide-details="isSmall"
                class="acc-font"
                outlined
                v-model="item.name"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="screenWidth < 600 ? '12' : '6'">
              <p class="acc-font">Degree</p>
              <v-text-field
                :hide-details="isSmall"
                class="acc-font"
                outlined
                v-model="item.degree"
              >
              </v-text-field>
            </v-col>
            <v-col :cols="screenWidth < 600 ? '12' : '6'">
              <p class="acc-font">Subject</p>
              <v-text-field
                :hide-details="isSmall"
                class="acc-font"
                outlined
                v-model="item.subject"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row>
            <v-col :cols="cols.info.left" class="d-flex align-center">
              <img :src="icons.workspace" alt="" />
            </v-col>
            <v-col :cols="cols.info.right">
              <h4 class="xl:text-base lg:text-base md:text-sm sm:text-xs">
                {{ item.degree }} in
                {{ item.subject }}
              </h4>
              <p class="ma-0 xl:text-sm lg:text-sm md:text-xs sm:text-xxs">
                {{ item.name }}
              </p>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-row v-if="outgoing.educational_information.length === 0">
        <v-col cols="12" class="d-flex align-center justify-center">
          No education information given
        </v-col>
      </v-row>
      <v-row v-show="editEducationInfo">
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            depressed
            color="primary"
            class="white--text mr-5"
            style="text-transform: none"
            @click="saveEducationInformation()"
            >Save</v-btn
          >
          <v-btn
            outlined
            color="grey lighten-1"
            style="text-transform: none"
            @click="cancelEditEducationInformation()"
            ><span class="black--text">Cancel</span></v-btn
          >
        </v-col>
      </v-row>
      <v-row v-show="editEducationInfo">
        <v-col>
          <span
            style="color: #0099dc"
            class="clickable"
            @click="addEducationInformation"
            >Add Education Information +</span
          >
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import _ from "lodash";
import TeacherWorkspaceIcon from "@af/global/assets/Teacher_Dashboard_Icon/teacher_workspace.svg";
import EditIcon from "@af/modules/user/assets/icons/Icon feather-edit.svg";
import DeleteIcon from "@af/modules/user/assets/icons/Icon feather-trash-2.svg";
import { FieldValidations } from "/global/utils/validations.js";

export default {
  name: "TeacherAccountDetailsForm",
  props: ["initialProfileData"],
  mixins: [FieldValidations],
  data() {
    return {
      readOnly: true,
      profile: undefined,
      editWorkInfo: false,
      editEducationInfo: false,
      showAvatarChageMenu: false,
      outgoing: {
        avatar_link: undefined,
        name: undefined,
        phone: undefined,
        address: undefined,
        about: undefined,
        professional_information: undefined,
        educational_information: undefined
      },
      localAvatar: undefined,
      clearAvatar: false,
      educationInfoAdded: 0,
      aboutTextMaxString: 500,
      rules: {
        required: [this.required],
      }
    };
  },
  created() {
    const isOnline = window.navigator.onLine;
    if ( !isOnline ){
      this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
      return;
    }
    this.profile = this.initialProfileData;
    this.populateOutgoing();
  },
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    isSmall() {
      return this.screenWidth < 600;
    },
    classes() {
      return {
        container: this.breakPointValues(["pa-0", "pa-0", "", ""]),
        headers: this.breakPointValues(["mb-2", "mb-5", "mb-5", "mb-6"]),
        editButton: this.readOnly ? "" : "inert invisible",
        profileCard: this.breakPointValues([
          "px-3 py-3",
          "px-5 py-6",
          "px-8 py-9",
          "px-8 py-9"
        ]),
        profileBox: this.breakPointValues(["pa-2", "pa-5", "pa-5", "pa-5"]),
        workCard: this.breakPointValues([
          "px-3 py-3",
          "px-5 py-6",
          "px-8 py-9",
          "px-8 py-9"
        ]),
        educationCard: this.breakPointValues([
          "px-3 py-3",
          "px-5 py-6",
          "px-8 py-9",
          "px-8 py-9"
        ]),
        avatar: {
          true: "",
          false:
            "white--text ft-bold xl:text-5xl lg:text-3xl md:text-3xl sm:text-2xl"
        },
        avatarMenu: this.breakPointValues([
          "px-3 py-0",
          "px-3 py-0",
          "px-3 py-0",
          "px-3 py-0"
        ])
      };
    },
    cols() {
      return {
        profile: {
          left: this.breakPointValues(["5", "5", "4", "4"]),
          right: this.breakPointValues(["7", "7", "8", "8"])
        },
        info: {
          left: this.breakPointValues(["2", "2", "1", "1"]),
          right: this.breakPointValues(["10", "10", "10", "10"])
        }
      };
    },
    sizes() {
      return {
        avatar: {
          height: this.breakPointValues([80, 100, 100, 100]),
          width: this.breakPointValues([80, 100, 100, 100])
        },
        editButton: {
          height: 20,
          width: 20,
          min: 20
        }
      };
    },
    icons() {
      return {
        workspace: TeacherWorkspaceIcon,
        editIcon: EditIcon,
        deleteIcon: DeleteIcon
      };
    },
    valid() {
      return this.$refs.phoneNumberField.validate();
    },
    initials() {
      let words = this.outgoing.name.split(" ");
      return words
        .map(word => word.charAt(0))
        .slice(0, 2)
        .join("")
        .toUpperCase();
    },
    avatarDisplayCondition() {
      return (
        this.existsOrHasValue(this.outgoing, "avatar_link") || this.localAvatar
      );
    }
  },
  methods: {
    breakPointValues(values) {
      let s = this.screenWidth;
      return s < 600
        ? values[0]
        : s < 961
        ? values[1]
        : s < 1903
        ? values[2]
        : values[3];
    },
    existsOrHasValue(object, key) {
      return object[key] === undefined
        ? false
        : object[key].length === 0
        ? false
        : true;
    },
    populateOutgoing(bio = true, work = true, education = true) {
      let hasAvatar =
        this.profile.avatar_link === undefined
          ? false
          : this.profile.avatar_link.length === 0
          ? false
          : true;
      let hasAddress =
        this.profile.address === undefined
          ? false
          : this.profile.address.length === 0
          ? false
          : true;
      let hasAbout =
        this.profile.about === undefined
          ? false
          : this.profile.about.length === 0
          ? false
          : true;
      let hasWorkInfo = this.profile.professional_information !== undefined;
      let hasEduInfo =
        this.profile.educational_information === undefined
          ? false
          : this.profile.educational_information.length === 0
          ? false
          : true;

      if (bio) {
        this.outgoing.avatar_link = hasAvatar
          ? this.profile.avatar_link
          : undefined;
        this.outgoing.name = this.profile.name;
        this.outgoing.phone = this.profile.phone;
        this.outgoing.address = hasAddress ? this.profile.address : undefined;
        this.outgoing.about = hasAbout ? this.profile.about : undefined;
      }
      if (work) {
        this.outgoing.professional_information = hasWorkInfo
          ? JSON.parse(JSON.stringify(this.profile.professional_information))
          : {
              na: true,
              name: undefined,
              designation: undefined,
              subject: undefined
            };
      }
      if (education) {
        this.outgoing.educational_information = hasEduInfo
          ? JSON.parse(JSON.stringify(this.profile.educational_information))
          : [];
      }
    },
    makeFormEditable() {
      this.readOnly = false;
    },
    makeReadOlny() {
      this.readOnly = true;
      this.totalGuardian = 1;
    },
    changeAvatar() {
      this.$refs.file.click();
    },
    loadImageFile(filelist) {
      const file = filelist[0];
      if (file.size > 10 * 1024 * 1024) {
        this.$root.$emit("alert", [undefined, "image size not more than 10MB"]);
      } else {
        this.localAvatar = file;
      }
    },
    removeAvatar() {
      if (this.localAvatar) {
        this.localAvatar = undefined;
      }
      if (this.outgoing.avatar_link || this.profile.avatar_link) {
        this.outgoing.avatar_link = "";
        this.clearAvatar = true;
      }
    },
    getImageURL() {
      if (this.localAvatar) {
        return URL.createObjectURL(this.localAvatar);
      } else {
        return this.outgoing.avatar_link;
      }
    },
    cancelBio() {
      this.readOnly = true;
      this.localAvatar = undefined;
      this.clearAvatar = false;
      this.populateOutgoing(true, false, false);
    },
    async saveBio() {
      if (!this.valid) {
        this.$root.$emit("alert", [undefined, "Phone number is not valid"]);
        return;
      }

      if (
        this.outgoing.address &&
        this.outgoing.address.length > this.aboutTextMaxString
      ) {
        this.$root.$emit("alert", [
          undefined,
          "Address must be in 500 character"
        ]);
        return;
      } else if (
        this.outgoing.about &&
        this.outgoing.about.length > this.aboutTextMaxString
      ) {
        this.$root.$emit("alert", [
          undefined,
          "About must be in 500 character"
        ]);
        return;
      }
      let isSave = await this.save(true, false, false);
      if (isSave) {
        this.readOnly = true;
        this.populateOutgoing(true, false, false);
      } else {
        this.readOnly = true;
        this.localAvatar = undefined;
        this.clearAvatar = false;
        this.populateOutgoing(true, false, false);
      }
    },

    cancelWorkInfo() {
      this.editWorkInfo = false;
      this.populateOutgoing(false, true, false);
    },
    async saveWorkInfo() {
      if (
        this.outgoing.professional_information.name.length === 0 ||
        this.outgoing.professional_information.designation.length === 0 ||
        this.outgoing.professional_information.subject.length === 0
      ) {
        this.$root.$emit("alert", [
          undefined,
          "Give required Work information"
        ]);
      } else {
        this.$delete(this.outgoing.professional_information, "na");
        this.editWorkInfo = false;
        let isSave = await this.save(false, true, false);
        if (isSave) {
          this.editWorkInfo = false;
          this.populateOutgoing(false, true, false);
        } else {
          this.editWorkInfo = false;
          this.populateOutgoing(false, true, false);
        }
      }
    },
    addEducationInformation() {
      this.outgoing.educational_information.push({
        name: undefined,
        degree: undefined,
        subject: undefined
      });
      this.educationInfoAdded++;
    },
    deleteEducationInformation(idx) {
      const left = this.outgoing.educational_information.slice(0, idx);
      const right = this.outgoing.educational_information.slice(idx + 1);
      this.outgoing.educational_information = left.concat(right);
    },
    cancelEditEducationInformation() {
      this.editEducationInfo = false;
      for (let i = 0; i < this.educationInfoAdded; i++) {
        this.outgoing.educational_information.pop();
      }
      this.populateOutgoing(false, false, true);
    },
    async saveEducationInformation() {
      let flag = [];
      if (this.outgoing.educational_information.length > 0) {
        for (
          let index = 0;
          index < this.outgoing.educational_information.length;
          index++
        ) {
          if (
            Object.keys(this.outgoing.educational_information[index]).length > 0
          ) {
            let deg = this.outgoing.educational_information[index].degree;
            let sub = this.outgoing.educational_information[index].subject;
            if (deg && sub) {
              if (deg.length != 0 && sub.length != 0) {
                flag[index] = true;
              }
            } else {
              flag[index] = false;
            }
          } else {
            flag[index] = false;
          }
        }
      } else {
        this.editEducationInfo = false;
        let isSave = await this.save(false, false, true);
        if (isSave) {
          this.editEducationInfo = false;
          this.populateOutgoing(false, false, true);
          return;
        } else {
          this.editEducationInfo = false;
          this.populateOutgoing(false, false, true);
          return;
        }
      }
      let allt = 0;
      for (let index = 0; index < flag.length; index++) {
        const element = flag[index];
        if (element) {
          allt = allt + 1;
        }
      }
      if (allt === this.outgoing.educational_information.length) {
        this.editEducationInfo = false;
        let isSave = await this.save(false, false, true);
        if (isSave) {
          this.editEducationInfo = false;
          this.populateOutgoing(false, false, true);
          return;
        } else {
          this.editEducationInfo = false;
          this.populateOutgoing(false, false, true);
          return;
        }
      } else {
        this.$root.$emit("alert", [
          undefined,
          "Please provide Degree and Subject Otherwise Delete"
        ]);
      }
    },
    async save(bio = true, work = true, education = true) {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ){
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      let profileData = _.cloneDeep(this.outgoing);
      if (bio && !work && !education) {
        this.$delete(profileData, "professional_information");
        this.$delete(profileData, "educational_information");
        //avatar
        if (this.localAvatar) {
          this.$set(profileData, "file_name", this.localAvatar.name);
        } else if (this.clearAvatar) {
          this.$set(profileData, "file_name", "");
        }
      } else if (!bio && work && !education) {
        this.$delete(profileData, "address");
        this.$delete(profileData, "about");
        this.$delete(profileData, "educational_information");
      } else if (!bio && !work && education) {
        this.$delete(profileData, "address");
        this.$delete(profileData, "about");
        this.$delete(profileData, "professional_information");
      }

      this.$delete(profileData, "avatar");

      //professional_information
      if (
        profileData.professional_information &&
        profileData.professional_information.na
      ) {
        this.$delete(profileData, "professional_information");
      }

      //address
      if (profileData.address === undefined) {
        this.$delete(profileData, "address");
      }

      //about
      if (profileData.about === undefined) {
        this.$delete(profileData, "about");
      }

      try {
        let resp = await this.$store.dispatch("userAF/updateTeacherProfile", {
          profile: profileData
        });
        if ("status" in resp) {
          if (resp.status.message === "success") {
            this.$emit("save");
            this.$root.$emit("alert", ["Success", "Saved"]);
            await this.getProfileInfo();
            return true;
          }
          if (resp.status.message === "deleted") {
            this.$emit("save");
            this.$root.$emit("alert", ["Success", "Image Deleted and Saved"]);
            await this.getProfileInfo();
            return true;
          }
        }
        await this.$store.dispatch("userAF/updateAvatar", {
          policy: resp.info,
          fileBlob: this.localAvatar
        });
        this.$emit("save");
        this.$root.$emit("alert", ["Success", "Saved"]);
        await this.getProfileInfo();
        return true;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
        return false;
      }
    },
    async getProfileInfo() {
      try {
        this.profile = await this.$store.dispatch("userAF/getTeacherProfile");
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.acc-font {
  font-size: 12px;
  color: #272727;
}

.name-input:focus {
  outline: none;
}

.avatar-edit-button {
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(3px);
  color: black;
  border-radius: 75%;
  position: absolute;
  bottom: 0;
}
</style>
